body{
  background: linear-gradient(to top, #000, #181100);
  /* background: linear-gradient(to top, #000, #181100); */
  /* background: #150c17; */
  background-color: #000;
  color: #fff;
  max-height: 100vh;
  touch-action: none; /* Prevent touch actions */
  background-repeat: no-repeat;
  
}

p, em{
  color: #acacac;
 }

/* .loadingBg{
  background-image: url('/public/loading.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
} */

/* .scorebg{
  background-image: url('/public/scorebg.svg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position-y: 20px;
  background-color: #F3C025;
  background-blend-mode: multiply;
} */

.homescreen{
  background: linear-gradient(to top, #000, #181100);
  background-color: #000;
}

#root{
  height: 100%;
  position: relative;
  width: 100%;
}


.shadowtop{
  box-shadow: 0px -25px 60px -18px #f3ba2f85;
}

@media only screen and (max-width: 340px) {

  .small-text {
   font-size: 15px;
  }
  .small-text2 {
   font-size: 12px;
  }
  .small-text3 {
   font-size: 9px;
  }
  
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroller::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none!important;
  scrollbar-width: none!important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.claimdiv{
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}
.cards__container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.taskhead{
  background: linear-gradient(to top, #000, #181100);
}

.taskbg{
  background: linear-gradient(to top, #000, #181100);
  background-color: #180000;
  color: #fff;
  max-height: 100vh;
  touch-action: none; /* Prevent touch actions */
  background-repeat: no-repeat;
  /* padding-bottom: 150px; */
}


.heading{
  text-align: center;
}

.image-container {
  perspective: 1000px;
  display: inline-block;
}

.wobble-image {
  width: 220px;
  transition: transform .2s;
  filter: grayscale(.1);
}
.congratsani{
  transition: visibility 0.5s ease 0s;
}

@keyframes wobble-top {
  0%,
  100% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(-20deg);
  }
}

@keyframes wobble-bottom {
  0%,
  100% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(20deg);
  }
}

@keyframes wobble-left {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(-20deg);
  }
}

@keyframes wobble-right {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(20deg);
  }
}

.wobble-top {
  animation: wobble-top .2s ease;
}

.wobble-bottom {
  animation: wobble-bottom .2s ease;
}

.wobble-left {
  animation: wobble-left .2s ease;
}

.wobble-right {
  animation: wobble-right .2s ease;
}

.tapguru{
    font-size: 16px;
}
.tapguru{
    font-size: 14px;
}

@media only screen and (max-width: 350px) {

  .tapguru{
    font-size: 12px;
  }
  .tapguru2{
    font-size: 10px;
}


}

.spinner {
  width: 160px;
  height: 160px;
  position: fixed;
  border-radius: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}


.spin1{

  width: 110px;
  border: 10px solid #565661;
  height: 110px;
  position: relative;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulser{
  animation: pulsers 1.5s linear infinite;

}

.loaderan {  
  animation-name: pulse-zoomin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
    
  /* shorthand
  animation: pulse-zoomin 2s linear 0s infinite normal none;*/
}
@keyframes pulse-zoomin {
    
  0% {
    transform:scale(1);
    opacity: 1;
  }
  50% {
    transform:scale(0.6);
    opacity: .3;
  }
  100% {
    transform:scale(1);
    opacity: 1;
  }
}

@keyframes pulserss {
  0% {
    opacity: 1;

  }
  50% {
    opacity: .3;

  }
  75% {
    opacity: .6;

  }
  100% {
    opacity: 1;

  }
}

.spin2{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-left-color: #8580cf;

}

.spin3{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-right-color: orange;
  rotate: 70deg;
  z-index: 10;

}

.spin4{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-right-color: orange;
  rotate: 70deg;
  z-index: 10;

}

.spin5{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #383568;
  rotate: 218deg;
  z-index: 20;

}

.spin6{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #8580cf;
  rotate: 275deg;
  z-index: 20;

}

.spin7{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #bcb4a7;
  rotate: 32deg;
  z-index: 30;
}

.spin8{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #373568;
  rotate: -28deg;
  z-index: 30;

}

.spin9{

  border: 10px solid #383568;
  position: absolute;
  width: 140px;
  height: 140px;
  border-color: transparent;
  border-radius: 50%;
  border-top-color: #373568;
  rotate: 41deg;
  z-index: 25;

}



.tapmore { 
  animation-name: shake-center;
  animation-duration: .9s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
    
  /* shorthand
  animation: shake-center 1.2s linear 0s infinite normal none;*/
}
@keyframes shake-center {
    
  0% {
    transform:rotate(0deg);
    transform-origin:50% 50%;
  }
  10% {
    transform:rotate(8deg);
  }
  20% {
    transform:rotate(-10deg);
  }
  30% {
    transform:rotate(10deg);
  }
  40% {
    transform:rotate(-10deg);
  }
  50% {
    transform:rotate(10deg);
  }
  60% {
    transform:rotate(-10deg);
  }
  70% {
    transform:rotate(10deg);
  }
  80% {
    transform:rotate(-8deg);
  }
  90% {
    transform:rotate(8deg);
  }
  100% {
    transform:rotate(0deg);
    transform-origin:50% 50%;
  }
}


@media only screen and (max-width: 340px) {

  .small-text {
   font-size: 15px;
  }
  .small-text2 {
   font-size: 12px;
  }
  .small-text3 {
   font-size: 9px;
  }

  .boostImg{

    width: 30px;
    height: 30px;
    
  }
  .xxImg{

    width: 28px;
    height: 28px;
    
  }
  .boostTitle{
    font-size: 12px;
  }
  .boostAmount{
    font-size: 20px;

  }

  .cardios{
    padding: 16px;
  }
  .xxAmount{
    font-size: 24px;
  }
  .xxTitle{
    font-size: 12px;
  }

  .wobble-image{
    width: 160px;
    height: 160px;
  }

  .levelName{
    font-size: 12px;
  }

  .chooseExchange{
    font-size: 10px;
    text-wrap: nowrap;
  }
  .clickContainer{

    padding-bottom: 10px;
    padding-top: 10px;

  }

  .moreTaps{
    font-size: 10px;

  }

  .getBoosters{
    font-size: 13px;
  }

  .barTitle{
    font-size: 12px;
  }
  
  .levelImg{
    width: 14px;
    height: 14px;
  }
  
}


/* SpinImage.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-image {
  width: 200px; /* Adjust size as needed */
  cursor: pointer;
  animation: spin linear infinite; /* Infinite spin */
}

.spintap{
  animation: spin 150s linear infinite;
}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.glowbutton{
  -webkit-box-shadow:0px 0px 8px 0px rgb(255 255 255 / 86%);
-moz-box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 86%);
box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 86%);
}

.cards{
  /* -webkit-box-shadow:inset 0 0 16px 0 #351e1d; */
/* -moz-box-shadow: inset 0 0 16px 0 #351e1d; */
/* box-shadow: inset 0 0 16px 0 #351e1d; */
/* background: #ffdcdc17; */
background: #ffbfbf29;
/* border: 0.5px solid #644a4a61; */
}


.frosty{
  width: 350px;
  height: 500px;
  background: inherit;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  margin-left: -175px;
  margin-top: -250px;
  border-radius: 8px;
}
.frosty:before{
  width: 400px;
  height: 550px;
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  bottom: 0;
  right: 0;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.2);
  filter: blur(10px);
}

.bordercut{
  border-left: none;
  border-right: none;
}

.spinso{
  animation: spin 50s linear infinite;
}

#okx{
  filter: invert(1);
}

#htx{
  filter: invert(1);
}
#bingx{
  border-radius: 6px;
}


#ton-connect-button button{
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 50px;
  background: #F3C025;

}

#ton-connect-button svg{
  height: 28px;
  width: 28px;
  color: #000;
  fill: #000;
}
#ton-connect-button svg path{
  fill: #000;
}
#ton-connect-button div{
  font-size: 16px;
  color: #000;
}

.bg-btn4, .bg-btn{
  color: #000;
}

.levelbar{
  background: linear-gradient(to right, #9cdf95, #b6bcb6, #d98edf, #d98edf);
}


/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

/* GRID STYLING */

* {
  box-sizing: border-box;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474faa;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d2630aa;
  border-radius: 50%;
}

/* ALTERNATING ORBITS */

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin .8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

/* X-ROTATING BOXES */

.configure-border-1 {
  width: 115px;
  height: 115px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
  width: 115px;
  height: 115px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(63,249,220);
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

/* SOLAR SYSTEM */

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #fafbfC;
	border-radius: 50%;
} 

.earth-orbit {
	width: 165px;
	height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
	width: 120px;
	height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
	width: 90px;
	height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet {
	position: absolute;
	top: -5px;
  width: 10px;
  height: 10px;
	border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: #ffab91;
}

.leo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.blue-orbit {
	width: 165px;
	height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}

.green-orbit {
	width: 120px;
	height: 120px;
  border: 1px solid #91ffbfa5;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
	width: 90px;
	height: 90px;
  border: 1px solid #ffca91a5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
	width: 60px;
	height: 60px;
  border: 2px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb5b53;
  border-top: 3px solid transparent;
  animation: spin .5s linear 0s infinite;
}

	.loading-bar {
		display: flex;
		height: 6px;
		width: 100%;
		/* max-width: 320px; */
		/* box-shadow: inset 0px 0px 0px 1px #C8C8CD; */
		/* border-radius: 50px; */
		overflow: hidden;
  }
		
		@keyframes progress-animation {
			0% {width: 0%;}
			20% {width: 10%;}
			40% {width: 30%;}
			50% {width: 60%;}
			100% {width: 90%;}
		}

		.progress-bar {
			display: flex;
			height: 100%;
			width: 100%;
			background: linear-gradient(to right, #9cdf95, #b6bcb6, #d98edf, #d98edf);
			animation: progress-animation 5s ease-in-out;
		}

    .checking{
      font-size: clamp(16px, 6.4vw, 22px);
    
    }
    .textlow{
      font-size: clamp(12px, 4vw, 15px);
    
    }

    .rewardsbg{
      background-image: url('/public/starsbg2.webp');
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }



	.premiumsvg {		
    animation-name: flip-vertical;
		animation-duration: 3.2s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: alternate-reverse;
		animation-fill-mode: forwards;
			
		/* shorthand
		animation: flip-vertical 3.2s ease 0s infinite alternate-reverse forwards;*/
	}
	@keyframes flip-vertical {
			
		0% {
			transform:rotateY(0);
		}
		100% {
			transform:rotateY(180deg);
		}
	}


  @keyframes spinn {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-320px);
    }
  }
  
  .animate-spinn {
    animation: spinn 3s ease-out;
  }
  

  /* RouletteSpinner.css */
.roulette-container {
  background-color: #1e1e1e;
  color: #777;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.values-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.values-list::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 30px;
  background-color: #333;
  transform: translateY(-50%);
  z-index: 0;
  border-radius: 15px;
}

.value-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  z-index: 1;
  transition: all 0.3s ease;
}

.star {
  margin-right: 10px;
  font-size: 1.2em;
}

.value {
  font-size: 1.1em;
}

.value-item.selected {
  color: #ffd700;
  font-weight: bold;
  transform: scale(1.1);
}

.value-item.selected .star {
  color: #ffd700;
}

.spin-button {
  background-color: #ffd700;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.spin-button:hover {
  background-color: #ffea00;
}

.spin-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.leadershadow{
  box-shadow: 0px -25px 100px -5px #0a0a0a;
}


.marco {
  animation: spin 1.2s linear infinite;
}

.fanbg{
  background-image: url('/public/fanbg.webp');
  background-size: contain;
  background-repeat: repeat;
  background-position-y: 6px;
}

.spinning-circle {
  border: 4px dotted #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spinoo 20s linear infinite; /* Default speed */
  margin: 0 auto;
}

@keyframes spinoo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.safeUserData{
  background-color: hsl(0, 0%, 0%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
}